import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { store } from './store'
import './index.css'
import App from './App'
import theme from './theme/muiTheme'

import { init } from '@sentry/browser'

init({
  dsn: 'https://60344b9b6d2b5c0aa2119da0fa1d990f@o39702.ingest.sentry.io/4505781048049664',
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV,
})

const domNode = document.getElementById('root')
if (domNode) {
  createRoot(domNode).render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
  )
}
