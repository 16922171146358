import { createTheme } from '@mui/material/styles'
import { COLOURS } from './global'

const muiTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: COLOURS.BACKGROUND_CONTENT,
          color: COLOURS.FONT_COLOR,
        },
      },
    },
  },
})

export default muiTheme
